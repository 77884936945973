import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";

ReactGA.initialize("UA-204546603-2");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
export {DemoVideo} from "./components/Video/DemoVideo";