import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  FooterContainer,
  Language,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("USA Address")}</Language>
              <Para>
                1619 Broadway,
                <br /> New York, NY 10019 <br /> +1 (669) 237-2542 <br />
                <a href="mailto:info@orderit.today">info@orderit.today</a>
              </Para>
            </Col>

            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Bangladesh Address")}</Language>
              <Para>
                Apt: 3, House: 6/A (Ali), <br />1 Eskaton Garden Road, <br />
                New Eskaton, Dhaka. <br /> 01784945006 <br />
                <a href="mailto:info@orderit.today">info@orderit.today</a>
              </Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.png"
                  aria-label="homepage"
                  width="100px"
                  height="auto"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://twitter.com/OrderitToday/"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/company/orderit-today"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://www.facebook.com/orderit.today"
                src="facebook.png"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
